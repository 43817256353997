(function ($) {

  Drupal.behaviors.virtualEventsCarouselFormatterV1 = {
    attached: false,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;

      var $formatter = $('.js-virtual-events-carousel-formatter-v1', context);
      var $carousel = $();

      $formatter.each(function () {
        var $thisFormatter = $(this);
        var $currentCarousel = $thisFormatter.find('.js-virtual-events-carousel');
        var $arrowsDiv = $thisFormatter.find('.js-carousel-controls');
        var $dotsDiv = $thisFormatter.find('.js-carousel-dots');
        var basicSlideSelector = '.js-virtual-events-carousel__slide';
        var useResponsive = false;

        $carousel = $currentCarousel;

        var slidesToShow = parseInt($currentCarousel.data('slidesToShow'));

        if (isNaN(slidesToShow)) {
          slidesToShow = 1;
        } else if (slidesToShow > 3) {
          useResponsive = true;
        }
        var slidesToScroll = parseInt($currentCarousel.data('slidesToScroll'));

        if (isNaN(slidesToScroll)) {
          slidesToScroll = 1;
        }
        var pcDotsArrowsArgs = self.checkDotsAndArrows($currentCarousel.data('dotsArrowsPc'));
        var mobileDotsArrowsArgs = self.checkDotsAndArrows($currentCarousel.data('dotsArrowsMobile'));
        var isRTL = site.direction.isRTL && Drupal?.settings?.forceSlickRTL;
        var settingsPlain = {
          appendArrows: $arrowsDiv,
          slide: basicSlideSelector,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          rtl: isRTL,
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows,
          onInit: function () {
            $.event.trigger({
              type: 'virtual_events_carousel:carousel_init',
              payload: 'plain'
            });
          }
        };
        var settingsResponsive = {
          appendArrows: $arrowsDiv,
          slide: basicSlideSelector,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          dots: pcDotsArrowsArgs.dots,
          rtl: isRTL,
          arrows: pcDotsArrowsArgs.arrows,
          appendDots: $dotsDiv,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: mobileDotsArrowsArgs.dots,
                arrows: mobileDotsArrowsArgs.arrows,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ],
          onInit: function () {
            $.event.trigger({
              type: 'virtual_events_carousel:carousel_init',
              payload: 'responsive'
            });
          }
        };

        if (useResponsive === 'true') {
          $carousel.not('.slick-initialized').slick(settingsPlain);
        } else {
          $carousel.not('.slick-initialized').slick(settingsResponsive);
        }
      });
    },
    checkDotsAndArrows: function (dotsAndArrows) {
      var dotsArrowsArgs = {};

      switch (dotsAndArrows) {
        case 'arrows':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = true;
          break;
        case 'dots':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = false;
          break;
        case 'both':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = true;
          break;
        case 'none':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = false;
          break;
      }

      return dotsArrowsArgs;
    }
  };
})(jQuery);
